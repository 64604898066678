import _ from 'lodash';
import { customAlphabet } from 'nanoid';

const alphabet = '0123456789abcdefghijklmnopqrstuvwxyz';
const nanoIdLowerAlphaNumaric = customAlphabet(alphabet);

export const APP_CONSTANT = {
	TOAST_TIMER: 5000
};

/**
 * Timestamp of Fixed date from when email verification has been started
 * Do Not Change (12-01-2021)
 */
export const startEmailVerificationTime = 1610444408000;

export const defaultPlan = 'pro';
export const CURRENCY_SYMBOL = {
	AED: 'د.إ',
	AFN: '؋',
	ALL: 'L',
	AMD: '֏',
	ANG: 'ƒ',
	AOA: 'Kz',
	ARS: '$',
	AUD: '$',
	AWG: 'ƒ',
	AZN: '₼',
	BAM: 'KM',
	BBD: '$',
	BDT: '৳',
	BGN: 'лв',
	BHD: '.د.ب',
	BIF: 'FBu',
	BMD: '$',
	BND: '$',
	BOB: '$b',
	BRL: 'R$',
	BSD: '$',
	BTC: '฿',
	BTN: 'Nu.',
	BWP: 'P',
	BYR: 'Br',
	BYN: 'Br',
	BZD: 'BZ$',
	CAD: '$',
	CDF: 'FC',
	CHF: 'CHF',
	CLP: '$',
	CNY: '¥',
	COP: '$',
	CRC: '₡',
	CUC: '$',
	CUP: '₱',
	CVE: '$',
	CZK: 'Kč',
	DJF: 'Fdj',
	DKK: 'kr',
	DOP: 'RD$',
	DZD: 'دج',
	EEK: 'kr',
	EGP: '£',
	ERN: 'Nfk',
	ETB: 'Br',
	ETH: 'Ξ',
	EUR: '€',
	FJD: '$',
	FKP: '£',
	GBP: '£',
	GEL: 'ლ',
	GGP: '£',
	GHC: '₵',
	GHS: 'GH₵',
	GIP: '£',
	GMD: 'D',
	GNF: 'FG',
	GTQ: 'Q',
	GYD: '$',
	HKD: '$',
	HNL: 'L',
	HRK: 'kn',
	HTG: 'G',
	HUF: 'Ft',
	IDR: 'Rp',
	ILS: '₪',
	IMP: '£',
	INR: '₹',
	IQD: 'ع.د',
	IRR: '﷼',
	ISK: 'kr',
	JEP: '£',
	JMD: 'J$',
	JOD: 'JD',
	JPY: '¥',
	KES: 'KSh',
	KGS: 'лв',
	KHR: '៛',
	KMF: 'CF',
	KPW: '₩',
	KRW: '₩',
	KWD: 'KD',
	KYD: '$',
	KZT: 'лв',
	LAK: '₭',
	LBP: '£',
	LKR: '₨',
	LRD: '$',
	LSL: 'M',
	LTC: 'Ł',
	LTL: 'Lt',
	LVL: 'Ls',
	LYD: 'LD',
	MAD: 'MAD',
	MDL: 'lei',
	MGA: 'Ar',
	MKD: 'ден',
	MMK: 'K',
	MNT: '₮',
	MOP: 'MOP$',
	MRO: 'UM',
	MRU: 'UM',
	MUR: '₨',
	MVR: 'Rf',
	MWK: 'MK',
	MXN: '$',
	MYR: 'RM',
	MZN: 'MT',
	NAD: '$',
	NGN: '₦',
	NIO: 'C$',
	NOK: 'kr',
	NPR: '₨',
	NZD: '$',
	OMR: '﷼',
	PAB: 'B/.',
	PEN: 'S/.',
	PGK: 'K',
	PHP: '₱',
	PKR: '₨',
	PLN: 'zł',
	PYG: 'Gs',
	QAR: '﷼',
	RMB: '￥',
	RON: 'lei',
	RSD: 'Дин.',
	RUB: '₽',
	RWF: 'R₣',
	SAR: '﷼',
	SBD: '$',
	SCR: '₨',
	SDG: 'ج.س.',
	SEK: 'kr',
	SGD: '$',
	SHP: '£',
	SLL: 'Le',
	SOS: 'S',
	SRD: '$',
	SSP: '£',
	STD: 'Db',
	STN: 'Db',
	SVC: '$',
	SYP: '£',
	SZL: 'E',
	THB: '฿',
	TJS: 'SM',
	TMT: 'T',
	TND: 'د.ت',
	TOP: 'T$',
	TRL: '₤',
	TRY: '₺',
	TTD: 'TT$',
	TVD: '$',
	TWD: 'NT$',
	TZS: 'TSh',
	UAH: '₴',
	UGX: 'USh',
	USD: '$',
	UYU: '$U',
	UZS: 'лв',
	VEF: 'Bs',
	VND: '₫',
	VUV: 'VT',
	WST: 'WS$',
	XAF: 'FCFA',
	XBT: 'Ƀ',
	XCD: '$',
	XOF: 'CFA',
	XPF: '₣',
	YER: '﷼',
	ZAR: 'R',
	ZWD: 'Z$'
};

export const getCurrencySymbol = (currencyCode: string): string => {
	const currencyCodeKey = currencyCode.toUpperCase();
	if (currencyCodeKey && CURRENCY_SYMBOL.hasOwnProperty(currencyCodeKey)) {
		return CURRENCY_SYMBOL[currencyCodeKey];
	} else {
		return '$';
	}
};

export const SEPA_PAYMENT_SUPPORTED_COUNTRY = [
	{ text: 'Austria', id: 'AT' },
	{ text: 'Belgium', id: 'BE' },
	{ text: 'Denmark', id: 'DK' },
	{ text: 'Estonia', id: 'EE' },
	{ text: 'Finland', id: 'FI' },
	{ text: 'France', id: 'FR' },
	{ text: 'Germany', id: 'DE' },
	{ text: 'Ireland', id: 'IE' },
	{ text: 'Italy', id: 'IT' },
	{ text: 'Lithuania', id: 'LT' },
	{ text: 'Luxembourg', id: 'LU' },
	{ text: 'Netherlands', id: 'NL' },
	{ text: 'Norway', id: 'NO' },
	{ text: 'Portugal', id: 'PT' },
	{ text: 'Spain', id: 'ES' },
	{ text: 'Sweden', id: 'SE' },
	{ text: 'United Kingdom', id: 'GB' }
];

export const SEPA_PAYMENT_DEFAULT_COUNTRY = { text: 'Germany', id: 'DE' };
export const defaultProfilePicture = 'assets/img/default/grey.png';
export const months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December'
];
// export const USER_COM_API_KEY = 'y5MVsL';
// export const USER_COM_API_KEY = 'YOAW7Q';

export const HIDE_POSTAL_CODE_KEY = 'slen';
export const HIDE_POSTAL_CODE_VALUE = 'nufy';

export const DELAY_INTERVAL = {
	day: 'Days',
	month: 'Months',
	year: 'Year',
	week: 'Weeks'
};

export const isValidJsonString = (string: string) => {
	try {
		JSON.parse(string);
	} catch (error) {
		return false;
	}
	return true;
};

export const decode = (str: string) => {
	let decodedValue = "";
	try {
		decodedValue = decodeURIComponent(str);
	} catch (error) {
		if (error.message === 'URI malformed') {
			decodedValue = unescape(str);
		}
	}
	return decodedValue;
};

export const uniqueId = (size = 21) => {
	return nanoIdLowerAlphaNumaric(size);
}

/**
 * Remove queries from cache
 * @params queries
 * @return void
 */
export const purgeQueryCache = (queries: string[]) => {
	if (!_.isEmpty(queries) && _.isArray(queries)) {
		// queries.forEach(query => {
		// 	cache.evict({ id: 'ROOT_QUERY', fieldName: query });
		// 	cache.gc();
		// });
	}
}

export const zeroDecimalCurrency = [
	'BIF',
	'CLP',
	'DJF',
	'GNF',
	'JPY',
	'KMF',
	'KRW',
	'XOF',
	'XPF',
	'XAF',
	'VND',
	'VUV',
	'UGX',
	'RWF',
	'PYG',
	'MGA'
];

export const PLAN_INTERVAL_SHORT_FORMS: { [key: string]: string } = {
	'day': 'D',
	'week': 'WK',
	'month': 'MO',
	'year': 'YR'
}

export const getIp = async (): Promise<string> => {
	try {
		let ip: string = localStorage.getItem('_ip');

		if (ip) return ip;

		ip = (await (await fetch('https://api.ipify.org?format=json')).json())?.ip || '';

		if (ip) localStorage.setItem('_ip', ip)

		return ip;
	} catch (error) {
		return '';
	}
}

export const STRIPE_APPEARANCE_ELEMENT = {
	theme: 'flat',
	variables: {
		fontWeightNormal: '500',
		borderRadius: '2px',
		colorBackground: 'white',
		colorPrimary: '#5e72e4',
		colorPrimaryText: 'white',
		spacingGridRow: '15px'
	},
	rules: {
		'.Label': {
			marginBottom: '6px'
		},
		'.Tab, .Input, .Block': {
			boxShadow: '0px 3px 10px rgba(18, 42, 66, 0.08)',
			padding: '12px'
		}
	}
}

export const extractErrorMessage = (err: any) => {
	console.log('err: ', err);
	let message: any = 'global.error.please_try_again';
	if (typeof err?.error === 'string' && !err?.error?.raw && !err?.error?.message) {
		message = err.error;
	} else if (err?.error?.error) {
		message = err.error.error;
	} else if (err?.error?.raw?.message) {
		message = err.error.raw.message;
	} else if (err?.error?.message) {
		message = err.error.message;
	} else if (err?.length && err[0]?.message) {
		message = err[0]?.message?.toLowerCase()
	} else if (err?.graphQLErrors?.length && err?.graphQLErrors[0]?.code) {
		message = err?.graphQLErrors[0]?.code?.toLowerCase();
	} else if (err?.graphQLErrors?.length && err?.graphQLErrors[0].message === 'already_member') {
		message = `teams.error.${err?.graphQLErrors[0].message}`
	} else if (err?.graphQLErrors?.length && err?.graphQLErrors[0]?.message) {
		message = err?.graphQLErrors[0]?.message?.toLowerCase();
	} else if (err?.message) {
		message = err.message;
	} else if (err?.code) {
		message = err.code;
	} else if (err?.networkError?.status === 0) {
		message = 'global.error.please_try_again';
	}
	return (typeof message === 'string') ? message : 'global.error.please_try_again';
}

export const DEFAULT_DATE_FORMAT = 'DD-MM-YYYY';

export const SETUP_FEE_LABEL = [
	'One Time Set Up Fees',
	'Einmal Set-Up Gebühren',
	'Una Hora De Establecer Las Tasas',
	'Frais uniques',
	'Uma Vez Criada Taxas'
];

export const toDataURL = (url: string) => fetch(url)
	.then(response => response.blob())
	.then(blob => new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.onloadend = () => resolve(reader.result)
		reader.onerror = reject
		reader.readAsDataURL(blob)
	}))
