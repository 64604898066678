import { Injectable } from '@angular/core';
import { extractErrorMessage } from '@app/constant';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

export interface Toast {
    message: string;
    classname?: string;
    delay?: number;
}

@Injectable({
    providedIn: 'root'
})
export class ToastService {
    public dataSource = new BehaviorSubject([{}]);
    currentToast = this.dataSource.asObservable();

    toasts: Toast[] = [];

    constructor(private readonly translateService: TranslateService) { }

    show(toast: Toast) {
        toast.message = this.translateService.instant(toast.message)
        this.toasts.push(toast);
    }

    remove(toast: Toast) {
        this.toasts = this.toasts.filter((t) => t !== toast);
    }

    clear() {
        this.toasts.splice(0, this.toasts.length);
    }

    error(message: string) {
        if (typeof message === 'string') {
            // message;
        } else {
            message = message[0]
        }
        const toast: Toast = {
            classname: 'bg-danger text-white',
            message
        }
        this.show(toast);
    }

    success(message: string) {
        const toast: Toast = {
            classname: 'bg-success text-white',
            message
        }
        this.show(toast);
    }

    info(message: string) {
        const toast: Toast = {
            classname: 'bg-info text-white',
            message
        }
        this.show(toast);
    }

    warning(message: string) {
        const toast: Toast = {
            classname: 'bg-warning text-white',
            message
        }
        this.show(toast);
    }

    setToast(message: any[]) {
        this.dataSource.next(message);
    }


    showApiError(err: any) {
        const toast: Toast = {
            classname: 'bg-warning text-white',
            message: extractErrorMessage(err)
        }
        this.show(toast);
    }
}
